import React, { useState, useEffect } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";

const filters = [
    {
      id: 1,
      text: "All",
    },
    {
      id: 2,
      text: "design",
    },
    {
      id: 3,
      text: "art",
    },
    {
      id: 4,
      text: "development",
    }
];



const alldata = PortfolioData;
const PortfolioRelative = ({ Column }) => {
    const [getAllItems] = useState(alldata);
    
    const [dataVisibleCount, setDataVisibleCount] = useState(3);
    const [dataIncrement] = useState(3) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setActiveFilter(e.target.textContent.toLowerCase());
        let tempData;
        if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
          tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
        } else {
          tempData = getAllItems.filter(
            (data) =>
              data.category === e.target.textContent.toLowerCase() &&
              data.id <= dataVisibleCount
          );
        }
        setVisibleItems(tempData);
    };

    
    return (
        <>
            <div className="row row--15">
                {visibleItems.map((item) => (
                    <div key={item.id} className={Column}>
                        <PortfolioItem portfolio={item} />
                    </div>
                ))}
            </div>
        </>
    )
}

export default PortfolioRelative;
