import React from 'react';
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from '../common/footer/FooterTwo';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import { FiArrowRight } from 'react-icons/fi';
import ContactForm from '../elements/contact/ContactForm';
import ServiceWeb from '../elements/service/ServiceWeb';
import ServiceBenefits from '../elements/service/ServiceBenefits';
import AdvanceWeb from '../elements/advancetab/AdvanceWeb';
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "/images/bg/about-banner.jpg",
        title: "Startup Your <br /> Business Agency.",
        description: "We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials."
    },
    
]

const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes',
        ],
    }
]


const WebDevelopment = () => {
    return (
        <>
            <SEO title="Web Development" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">

                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center justify-content-end">

                                        <div className="order-2 order-lg-2 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                            <div className="inner text-start">
                                                <h1 className="title color-white">WEB DEVELOPMENT SERVICES</h1>
                                                <p className="description color-white">If you want to stand out in a competitive niche, get your business to the next level, and reach a larger audience, you might need a great web presence for your business. <br></br>
                                                Code Aims is a web development company that delivers custom web development solutions for businesses.
                                                </p>
                                                <div className="button-group mt--30 mt_sm--20">
                                                    <a className="btn-default btn-medium btn-icon btn-border" href="#contact">Contact Now <i className="icon"><FiArrowRight /></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-5 order-1 order-lg-1">
                                            {PopupData.map((item) => (
                                                <div className="video-btn" key={item.id}>
                                                    <VideoTwo imagename="./images/about/about-6.png" galleryItem={item} />
                                                </div>
                                            ))}
                                        </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Services"
                                    title = "WEB DEVELOPMENT SERVICES"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceWeb
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                {/* Start Timline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Working Process"
                                    title = "Our Working Process."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timline Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Past Works"
                                        title = "FEATURED WORKS"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <AdvanceWeb />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Key Benefits"
                                    title = "YOUR BENEFITS. OUR KEY EXPERTISE"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceBenefits
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> 

                <Separator />

                {/* Contact Form  */}
                <div className="rwt-contact-area rn-section-gap" id='contact'>
                    <div className="container">
                        <div className="row mb--30">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Get In Touch"
                                    title = "WANT TO WORK WITH US?"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className='row mb--30 '>
                            <div className="col-lg-12">
                            <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* Contact Form  */}

                <FooterTwo />
            </main>
        </>
    )
}

export default WebDevelopment;
