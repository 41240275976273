import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from '../elements/separator/Separator';
import AccordionOne from '../elements/accordion/AccordionOne';
import { FiArrowRight } from 'react-icons/fi';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import Advance from '../elements/advancetab/Advance';
import ContactForm from '../elements/contact/ContactForm';


const Services = () => {

    return (
        <>
            <SEO title="Service || Code Aims" />
                <main className="page-wrapper">
                    <HeaderTopNews />
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                    <div className="slider-area slider-style-6 shape-right height-850">
                        <div className="container">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                    <div className="inner text-start">
                                        <h1 className="title color-white">WE TURN YOUR WISHES
                                            INTO BRILLIANT SOLUTIONS</h1>
                                        <p className="description color-white">We offer a full suite of website development and programming services, flexible pricing and superior service.</p>
                                        <div className="button-group mt--30 mt_sm--20">
                                            <a className="btn-default btn-medium btn-icon btn-border" href="#our-services">Our Services<i className="icon"><FiArrowRight /></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 order-1 order-lg-2">
                                    <div className="frame-image">
                                        <img src="./images/banner/banner-services.png" alt="Banner Images" />
                                    </div>
                                </div>
                                <div className="shape-image">
                                    <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gapTop" id='our-services'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of web development servicse <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-start"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Past Works"
                                        title = "FEATURED WORKS"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <Advance />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    {/* Start Accordion Area  */}
                     <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "FAQ"
                                        title = "Frequently Asked Questions"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt--35 row--20">
                                <div className="col-lg-10 offset-lg-1">
                                    <AccordionOne customStyle="rn-accordion-01" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Accordion Area  */}

                    <Separator />

                    {/* Contact Form  */}
                    <div className="rwt-contact-area rn-section-gap" id='contact'>
                        <div className="container">
                            <div className="row mb--30">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Get In Touch"
                                        title = "WANT TO WORK WITH US?"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className='row mb--30 '>
                                <div className="col-lg-12">
                                <ContactForm/>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* Contact Form  */}
 
                    <FooterTwo />
                    <Copyright />
                </main>

        </>
    )
}

export default Services;
