import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import Separator from "../elements/separator/Separator";
import RegistrationNote from '../elements/registrationNote/RegistrationNote';

const Registration = () => {
    return (
        <>
            <SEO title="Web Desgin & Development Classes" />
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-banner.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Register Now</span></h3>
                                    </div>
                                    <h1 className="title display-one">WEB DESGIN <br /> & DEVELOPMENT</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                {/* End Slider Area 

                

                 {/* Start Slider area  */}
                 {/* <div className="slider-area slider-style-7 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h4 class="subtitle rn-sub-badge"><span class="theme-gradient">Register Now</span></h4>
                                    <h1 className="title">WEB DESIGN <br /> & DEVELOPMENT</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Slider area  */}

                <Separator />

                
                {/* Start Elements Area  */}
                <div className="register-section">
                    <div className="container-fluid">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <RegistrationNote />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}


                
                <FooterFour />
            </main>
        </>
    )
}

export default Registration;
