import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const testimonialData = [
    {
        image: 'testimonial-01',
        name: 'Muhammad Jawed',
        designation: 'Sr Product Designer',
        location: 'CO Miego, AD, USA', 
        description: "“Are you looking for a web development team that can bring your vision to life? Code Aim's Web Developers have years of experience working with businesses to create custom websites that fit their brand.„",
       
    },
    {
        image: 'testimonial-02',
        name: 'Alina Yawari',
        designation: 'App Developer',
        location: 'Bangladesh', 
        description: "“We all know how important it is to have a website for businesses in today's digital world. But what about the quality of the website? This will provide an insight into testimonial reviews of Code Aim's web developers, and how they are helping businesses create high-quality websites that increase customer engagement.„",
      
    },
    {
        image: 'testimonial-03',
        name: 'Jonathan',
        designation: 'Accounts Manager',
        location: 'Poland', 
        description: "“Code Aim's provide Ecommerce site with the best design and development„",
    },
    {
        image: 'testimonial-04',
        name: 'Hussain Ali',
        designation: 'SEO Manager',
        location: 'Pakistan', 
        description: "“Code Aim’s WordPress design and development service offers a wide range of features to help businesses create custom themes and templates for their websites, resulting in a modern, professional look and easy navigation.„",
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                {testimonialData.map((data, index) => (
                    <div className={`${column}`} key={index}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className={`rn-box-card ${teamStyle}`}>
                                <div className="inner">
                                    <figure className="thumbnail">
                                        <img src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </figure>
                                    <figcaption className="content">
                                        <p className="description">{data.description}</p>
                                        <h2 className="title">{data.name}</h2>
                                        <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    </figcaption>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))}
            </Slider>
            
        </div>
    )
}

export default TestimonialOne;
