import React from 'react';
import { FiCheck } from "react-icons/fi";


const RegistrationNote = () => {
    return (

     <div className="form-container ">   

        {/* Notice board starts  */}
        <div className="notice-board">
             <h6>Notice Board:</h6>
             <ol>
                  <li> All communication regarding registration process, shortlisting process and class orientation details will be done through email or phone number so please make sure you provide the correct email address and phone number at time of registration.</li>
                  <li> Please check your spam or junk e-mail folder just in case email got delivered there instead of your Inbox.</li>
             </ol>
        </div>
        {/* Notice board ends  */}

         {/* term and conditions starts  */}
        <div className="terms">
             <h6>I agree with all Terms & Conditions that</h6>
             <ul>
                  <li> The Code Aims is not an offer of an employment and it is in no way associated with any future employment opportunity. The Program only aims to provide training to the candidate to enhance their professional capability and employability.</li>
                  <li> Candidates will be required to follow all the rules and regulations of the program including timings and confidentiality requirements.</li>
                  <li> The candidates will be awarded training completion certificates on successfully completing the program. These certificates will be based on formal performance evaluation and rating. Candidates will be evaluated on attendance, student’s performance and other assignments, which may be assigned during the span of course.</li>
                  <li> The duration of the training is 3 months. However, the training of a candidate can be discontinued at any stage without any reason as per requirement of the project</li>
                  <li> The submission of application does not guarantee that your application will be successful for enrollment in the program.</li>
                  <li> Any decision made in the interest of this project is not challengeable in court / judiciary.</li>
                  <li> Classes can be rescheduled at any point in time as per requirement of the project.</li>
                  <li> Any decision regarding training or transfer of the center as per requirement of the project, is not challengeable in any court/judiciary.</li>
                  <li> Candidate must be available during the training hours to attend this training.</li>
             </ul>
        </div>
         {/* term and conditions ends  */}

        {/* form section starts  */}
        <form action="" name="registrationForm" className="form-sec" method="post">
              {/* personal informatin starts  */}
             <div className="user-info">
                  <div className="nav-user-info row row--0">
                       <div className="head col-lg-4 col-md-4 col-12">
                            Personal Information
                       </div>
                       <div className="note col-lg-8 col-md-8 col-12">
                            Note: Kindly give your own CNIC No/your parents/guardians. Also ensure that the information provided is correct.
                       </div>
                  </div>
                  <div className="form-group row  row--15 ">
                       <div className='col-lg-6 col-md-6 col-12' >
                            <label htmlFor="name">Full Name <span>*</span>
                            </label>
                            <input type="text" id="name" name="FullName" required /> 
                       </div>
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="fname">Father's Name <span>*</span>
                            </label>
                            <input type="text" name="FatherName" id="fname" required />
                       </div>
                       
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="cour">Choose your Course <span>*</span>
                            </label>
                            <select name="COURSE" id="cour" required>
                                 <option value="--- Please Select ---">--- Please Select ---</option>
                                 <option value="Beginner Development">Beginner Development</option>
                                 <option value="Advance Development">Advance Development</option>
                            </select>
                       </div>
                      
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="prov">Province <span>*</span>
                            </label>
                            <select name="Province" id="prov" required>
                                 <option value="--- Please Select ---">--- Please Select ---</option>
                                 <option value="Balochistan">Balochistan</option>
                                 <option value="Sindh">Sindh</option>
                                 <option value="Punjab">Punjab</option>
                                 <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                                 <option value="5">Gilgit  Baltistan</option>
                                 <option value="Gilgit  Baltistan">Azad Kashmir</option>
                                 <option value="Islamabad Capital Territory">Islamabad Capital Territory</option>
                            </select>
                       </div>
                  </div>
             </div>
            {/* personal information ends   */}
            {/* academic information starts  */}
             <div className="user-info">
                  <div className="nav-user-info row row--0">
                       <div className="head col-lg-4 col-md-4 col-12">
                            Academic Information
                       </div>
                       <div className="note col-lg-8 col-md-8   col-12">
                            Note: Fill in the details of your latest degree in this section. 
                       </div>
                  </div>
                  <div className="form-group row">
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="degree">
                                 School / College last attended <span>*</span>
                            </label>
                            <input type="text" id="degree" name="SchoolAttend" placeholder="--Please Enter--" required />
                       </div>
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="marksObtained">
                                 Marks Obtained <span>*</span>
                            </label>
                            <input type="text" id="marksObtained" name="marksObtained" placeholder="--Please Enter--" required />
                       </div>
                  </div>
             </div>
             {/* academic information ends */}
              {/* Contact information starts */}
             <div className="user-info">
                  <div className="nav-user-info row row--0">
                       <div className="head col-lg-4 col-md-4  col-12">
                            Contact Information
                       </div>
                       <div className="note col-lg-8 col-md-8  col-12">
                            Note: All the correspondence (such as login information) will be done via email, so kindly enter your personal active email. Also the mobile number should be your own or your parents/ guardians.
                       </div>
                  </div>
                  <div className="form-group row">
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="email">Email Address <span>*</span>
                            </label>
                            <input type="email" name="Email" id="email" required />
                       </div>
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="mobile">
                                 Mobile No. <span>*</span>
                            </label>
                            <input type="tel" name="Telephone" id="mobile" placeholder="____-_______" required />
                       </div>
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="city">City <span>*</span></label>
                            <input type="text" id="city" className="city" name="City" placeholder="-- Enter Your Current City --" required />
                       </div>
                       <div className='col-lg-6 col-md-6 col-12'>
                            <label htmlFor="address">Address <span>*</span></label>
                            <input  type='text' name="Address" className='address' id="address" placeholder="-- Enter Your Current Address --" required />
                       </div>
                  </div>
             </div>
             {/* academic information ends  */}
             <div className="submit-form">
                <button type="submit" name='submit' className='btn-default btn-large'  onClick="message()">Submit Now</button>
            </div>
        </form>
        {/* form section ends */}
        
    </div>
        
    )
}
export default RegistrationNote;
