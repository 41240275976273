import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>
            
            <li><Link to="/about-us">About</Link></li>
            <li className="has-droupdown"><Link to="/services">Services</Link>
                <ul className="submenu">
                    <li><Link to="/web-development">Web Development</Link></li>
                    {/* <li><Link to="/design">Design</Link></li> */}
                    {/* <li><Link to="/digital-marketing">Digital Marketing</Link></li> */}
                </ul>
            </li>

            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/blogs">Blog</Link></li>
            
            <li><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
