import React from 'react';
import SEO from "../../common/SEO";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import HeaderTopNews from '../../common/header/HeaderTopNews';
import HeaderOne from '../../common/header/HeaderOne';
import BestLayout from '../../common/BestLayout';
import Separator from '../separator/Separator';



const Portfolio = () => {

    return (
        <>
            <SEO title="Portfolio || Code Aims" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderTopNews />
                    <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-banner.jpg)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge"><span className="theme-gradient">Latest Portfolio</span></h3>
                                        </div>
                                        <h1 className="title display-one">Our Works</h1>
                                        <p className="description ">  We invite you to explore our portfolio and see how we are making a difference in our communities ..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">

                        <div className="rwt-portfolio-area rn-section-gap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "CHECK OUT SOME OF OUR MOST RECENT WORKS"
                                            title = "Our Portfolio"
                                            description = ""
                                        />
                                        <div className='p-20'>
                                            <Separator />
                                        </div>
                                    </div>
                                </div>
                                <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
                            </div>
                        </div>

                    </div>
                </main>
            </BestLayout>
        </>
    )
}
export default Portfolio;
