import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabTwo = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">

                                        <div className="thumbnail mb-5">
                                            <img src="./images/portfolio/personal-landing-banner.jpg" alt="Personal Landing" />
                                        </div>

                                        <h4 className='title'>Personal</h4>
                                        <p className='description'>
                                        Digital marketing efforts, instead of handling in-house. They can provide your business with a variety of digital solutions to promote your product or service online and help you hit your marketing goals and grow your business.
                                        </p>

                                        <Link className="btn-default"  to="/portfolio">See Full Case</Link>

                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail mb-5">
                                            <img src="./images/portfolio/ibrahim-memon-landing.jpg" alt="Ibrahim Memon Landing" />
                                        </div>

                                        <h4 className='title'>Ibrahim Memon</h4>
                                        <p className='description'>
                                        As a passionate software engineer, I thrive on the intricate dance between logic and creativity. Currently immersed in the dynamic world of WebHR, my expertise centers around React Native, where I seamlessly blend technology with innovation.
                                        </p>

                                        <Link className="btn-default"  to="/portfolio">See Full Case</Link>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail mb-5">
                                            <img src="./images/portfolio/chabook-landing-banner.jpg" alt="Chabook Landing" />
                                        </div>

                                        <h4 className='title'>Chabook Services</h4>
                                        <p className='description'>
                                        that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.
                                        </p>

                                        <Link className="btn-default"  to="/portfolio">See Full Case</Link>
                                    </div>
                                </div>
                            </TabPanel>

                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Personal</h4>
                                        <p className="description">Agency provides a full service range including technical skills, design, business understanding.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Ibrahim Memon</h4>
                                        <p className="description">Passionate Software Engineer with a focus on React Native development, dedicated to crafting elegant and user-friendly mobile applications.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Chabook Services</h4>
                                        <p className="description">In a typical React application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabTwo;
