import React from 'react';
import FooterThree from './footer/FooterThree';
import FooterOne from './footer/FooterOne';
import FooterTwo from './footer/FooterTwo';
import FooterFour from './footer/FooterFour';
import NewsletterOne from './footer/NewsletterOne';
import MainFooter from './footer/MainFooter';
import Copyright from './footer/Copyright';
import CopyrightTwo from './footer/CopyrightTwo';

const BestLayout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                {children}
                <NewsletterOne newsletetrStyle="rn-newsletter-default " extraClass="border-top-only" />
                <FooterThree />
            </main>
        </>
    )
}
export default BestLayout;
    