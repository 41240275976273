import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    // {
    //     icon: <FiActivity />,
    //     title: 'Web Development',
    //     description: 'Web development is the process of building, creating and maintaining websites. It includes aspects such as web engineering, web design, web content development, server-side/client-side scripting, network security configuration and e-commerce development.'
    // },
    // {
    //     icon: <FiCast />,
    //     title: 'WordPress Website',
    //     description: 'WordPress is a highly use content management system (CMS) for making blog site, Personal site, Agency site and other well-designed sites. The layout is very user-friendly and the site looks great. The content is well-written, highly customizable, plug-ins and the site is updated regularly.'
    // },
    // {
    //     icon: <FiMap />,
    //     title: 'Personal portfolio Website',
    //     description: 'WordPress is a best way to make Personal Portfolio website which can help you with your showcase and creative works. It can be use to showcase your photography, art, and writing skills. It also includes a blog where you can post about your work and your thoughts.'
    // },
    // {
    //     icon: <FiMap />,
    //     title: 'Shopify Site',
    //     description: 'Creating a Shopify website is an exciting venture for business owners who want to establish an online presence. Shopify is a cloud-based, multi-channel commerce platform designed. Shopify provides various features such as customizing the look of a store, adding products, managing orders, tracking shipments, and much more.'
    // },
    // {
    //     icon: <FiMap />,
    //     title: 'UX / UI Design',
    //     description: 'We develop the overall layout and production design for advertisements, brochures, magazines, and corporate reports. Graphic designers also use digital tools to create website designs, logos, infographics, and other digital content.'
    // },
    // {
    //     icon: <FiMap />,
    //     title: 'Video Editing',
    //     description: 'We edit videos using the basic tools and techniques. It covers the basics of video editing, including how to create a storyboard, add music and sound effects, edit video clips, graphics, and effects.'
    // },



    // {
    //     icon: <FiActivity />,
    //     title: 'We are creative',
    //     description: 'We are masters in innovative, agile creative approaches in producing websites. Our refreshing way of working brings the best possible results for our clients. We deliver beautifully crafted projects with passion.'
    // },
    
    // {
    //     icon: <FiMap />,
    //     title: 'We use the latest technologies',
    //     description: 'We create highly-usable websites that are powered by an array of technologies and then we analyze performance data to optimize and refine.'
    // },
    // {
    //     icon: <FiMap />,
    //     title: 'Best Team',
    //     description: 'Our team provides personal, professional and friendly service which carefully considers your business goals and requirements. We transform innovative visions into brilliant realities. Got one? Let’s make outstanding work together!'
    // },

    

    {
        icon: <FiActivity />,
        title: 'Web Develoment',
        description: "<ol style='display: flex; flex-direction: column;'> <li> <a href='/'>Website Development</a> </li> <li> <a href='/'>Coporate Website</a> </li> <li> <a href='/'>Startup Website</a> </li> <li> <a href='/'>Landing and Promo sites</a> </li> <li> <a href='/'>E-commerce</a> </li> <li> <a href='/'>WordPress Development</a> </li> <li> <a href='/'>Fixed Price Website</a> </li> </ol>"
    },
    
    {
        icon: <FiMap />,
        title: 'Design',
        description: "<ol style='display: flex; flex-direction: column;'> <li> <a href='/'>Web Design</a> </li> <li> <a href='/'>UX / UI Design</a> </li> <li> <a href='/'>Logo Design</a> </li> <li> <a href='/'>Branding Identity</a> </li> <li> <a href='/'>Banner Ad Design</a> </li> </ol>"
    },
    {
        icon: <FiMap />,
        title: 'Digital Marketing',
        description: "<ol style='display: flex; flex-direction: column;'> <li> <a href='/'>Website marketing strategy</a> </li> <li> <a href='/'>Content marketing</a> </li> <li> <a href='/'>PPC services</a> </li> <li> <a href='/'>Social media marketing services</a> </li> </ol>"
    },
]




const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;